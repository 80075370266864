.contact-form {
    width: 100%;
    margin-top: 20px;

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            // opacity: 0;
            animation: fadeinUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }

        li.half {
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    input[type='text'],
    input[type='email'] {
        width: 100%;
        border: 0;
        background: #181818;
        height: 50px;
        font-size: 20px;
        color: #fff;
        padding: 0 20px;
        box-sizing: border-box;
    }
    textarea {
        width: 100%;
        border: 0;
        background: #181818;
        height: 50px;
        font-size: 20px;
        color: #fff;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;

    }

    .flat-button {
        color: #01aa6d;
        background: transparent;
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 2px;
        text-decoration: none;
        padding: 8px 10px;
        border: 1px solid #01aa6d;
        float: left;
        float: right;
        border-radius: 4px;
        margin-right: 10px;
        text-align: center;
        cursor: pointer;
    }
    .flat-button:hover{
        background-color: #01aa6d;
        color: black;
    }
}
.info-map{
    position: absolute;
    background-color: #181818;
    top: 50px;
    right: 5%;
    z-index: 999999999;
    width: 150px;
    padding: 20px;
    color: #fff;
    font-family: Helvetica;
    font-size: 17px;
    font-weight: 400;
    opacity: 0;
    animation:  fadeIn 1s 1.5s;
    animation-fill-mode: forwards;

    span {
        font-size: 16px;
        display: block;
        padding-top: 20px;
        color: #01aa6d;
    }
}
.map-wrap {
    background: rgba(8, 253, 216, 0.1);
    float: right;
    width: 53%;
    height: 100%;
    
    
}
.leaflet-container {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
    animation: backInRight 1s 1,2s;
    animation-fill-mode: forwards;
}
@media (max-width: 800px) {
    .page{

    
    .container{
       .contact-page{
            width: 100%;
        }
        .contact-form {
            margin-bottom: 40px;
        }
        
        .text-zone{
            width: 100%;

           h1{
            &::before{
                left: 1px;
            }
            &::after{
                left: 16px;
            }
           }
        }
    }
    .map-wrap {
        display: none;
  
    }
    .info-map{
        display: none;
    }
}
}

@media (max-width: 1000px) {
    .page{
        .top-tags{
            top: 16px;
            left: 68px;
        }
    }
    .text-zone{
        width: 100%;

       h1{
        font-size: 4.0rem;
        &::before{
            top: 4px;
            left: 1px;
        }
        &::after{
            left: 16px;
        }
       }
    }
    .info-map{
        display: none;
    }

}