.nav-bar {
    background: #181818;
    width: 60px;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 3;
    // height: 500px;

    .logo{
        display: block;
        padding: 8px 0;
        
        img{
            display: block;
            margin: 0 auto;
            width: 52px;
            height: auto;

            &.sub-logo {
                width: 50px;
            }
        }
    }
    .nav-bar__menu,
    .nav-bar__close{
      opacity: 0;
    }
    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;

        a {
            font-size: 2.8rem;
            color: #4d4d4e;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;

            i {
                transition: all 0.3s ease-out;
            }
            &:hover{
                color: #01aa6d;

                svg {
                    opacity: 0;
                }
                &:after {
                    opacity: 1;
                }
            }
            &:after {
                content: '';
                font-size: 9px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                -webkit-transition: all 0.3s ease-out;
                transition: all 0.3s ease-out
            }
            &:first-child {
                &:after{
                    content: 'HOME';
                }
            }

        }
        a.about-link{
            &:after {
                content: 'ABOUT';
            }

        }
        a.contact-link{
            &:after {
                content: 'CONTACT';
            }

        }
        a.projects-link{
            &:after {
                content: 'PROJECTS';
            }

        }
        a.active{
            svg {
                color: #01aa6d;
            }

        }
    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;

        li {
            a {
                padding: 7px 0;
                display: block;
                font-size: 2.4rem;
                line-height: 16px;
                color: #4d4d4e;


                &:hover svg {
                    
                        color: #01aa6d;
                
                }
            }

        }
    }
}
@media (max-width: 800px){
    .nav-bar{
        position: fixed;
        width: 100%;
        height: 80px;

        .logo{
            position: absolute;
            margin-left: 5%;
        }
        .nav-bar__menu {
            height: 40px;
            width: 40px;
            position: absolute;
            top: 16px;
            right: 12px;
            cursor: pointer;
            opacity: 1;

            &.hide{
                display: none;
            }
        }
        .nav-bar__close {
            height: 40px;
            width: 40px;
            position: absolute;
            top: 16px;
            right: 12px;
            cursor: pointer;
            opacity: 1;
           

            &.hide{
                display: none;
            }
        }

        nav{
            display: flex;
            height: 47px;
            top: 208px;
            justify-content: space-around;
            background-color: #181818;
            transition: 1s;
            left: 100%;    
             
            &.hide{
                left: 0;
            }
        }
        ul{
            display: flex;
            justify-content: space-evenly;
            background: #181818;
            position: inherit;
            bottom: 0;
            
        }
    }
    
}

