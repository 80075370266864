.page{
    width: 100%;
    height: 100%;
    position: absolute;
}
.top-tags {
    bottom:auto;
    top: 35px;
}
.tags {
    color: #01aa6d;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 18px;
    font-family: 'La Belle Aurore';
}
.bottom-tag-html {
    margin-left: -20px;
}
.container {
    width: 100%;
    will-change: contents;
    height: 100vh;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}
.about-page,
.contact-page,
.projects-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;
    }

        h1 {
            font-size: 53px;
            font-family: 'Coolvetica';
            color: #01aa6d;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 40px;
            left: -10px;

            &::before {
                content: '<h1>';
                color: #01aa6d;
                font-family: "La Belle Aurore";
                font-size: 18px;
                position: absolute;
                margin-top: -10px;
                left: -10px;
                opacity: 0.6;
                line-height: 18px;
            }

            &::after {
                content: '</h1>';
                color: #01aa6d;
                font-family: "la Belle Aurore";
                font-size: 18px;
                position: absolute;
                bottom: -20px;
                left: -30px;
                opacity: 0.6;
                line-height: 18px;
            }
        }
        p {
            font-size: 16px;
            color: #fff;
            font-family: sans-serif;
            font-weight: 300;
            min-width: fit-content;
            animation: pulse 1s;

            &:nth-of-type(1) {
                animation-delay: 1.1s;
            }
            &:nth-of-type(2) {
                animation-delay: 1.2s;
            }
            &:nth-of-type(3) {
                animation-delay: 1.3s;
            }
        }
    

    .text-animate-hover {
        &:hover {
            color: #fff;
        }
    }
}



@media (max-width: 800px){
    .page {
        height: 95vh;
        top: 50px;
        position: relative;

        .tags{
            display: none;
        }
        // h1{
        //     &::before,
        //     &::after{
        //         // display: none;
        //     }
        // }
    }
    .container {
        width: 100%;
        height: auto;
        padding-bottom: 58px;
        box-sizing: border-box;
        position: relative;
        top: 0;
        overflow: hidden;

        &.contact-page,
        &.about-page,
        &.projects-page {
            .text-zone{
                position: initial;
                transform: none;
                width: 90%;
                display: block;
                padding: 20px;
                box-sizing: border-box;
            }
        }
        &.about-page{
            margin-top: 60px;
            
        }
    }
    
    
}

@media (max-width: 600px){
    .page {
        height: 95vh;
        top: 50px;
        position: relative;
      
    }
    .container {
        width: 100%;
        height: auto;
        padding-bottom: 58px;
        box-sizing: border-box;
        position: relative;
        top: 60px;
        overflow: inherit;

        &.contact-page,
        &.about-page,
        &.projects-page {
            .text-zone{
                position: initial;
                transform: none;
                width: 90%;
                display: block;
                padding: 20px;
                box-sizing: border-box;
            }

            h1{
                font-size: 3.0rem;
            }
            p{
                font-size: 1.4rem;
            }
        }
        &.about-page{
            margin-top: 0;
            
        }
    }
    
 
}



