 .logo-container {
   z-index: 0;
    width: 30%;
    height: 525px;
    position: absolute;
    top: 30%;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;
    
        animation: rubberBand;
        animation-duration: 1s;
        animation-delay: 2s;
        
    .solid-logo{
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 100%;
        transform: rotate(30deg) !important;
        z-index: 1;
        // transition: 1s;
        
       
    }
   
}