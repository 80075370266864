.projects-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 200px);
    position: initial;
    height: 100vh;
    overflow: auto;

    h1 {
        margin-left: 100px;
        margin-top: 100px;
    }

    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        height: auto;
        padding-bottom: 100px;
        justify-content: center;             
    
    }

   
    .images-box {
   
        position: relative;
        flex: none;
        flex-flow: row;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        width: 300px;
        cursor: pointer;
        border: solid 3px #01aa6d;

        
           
            
            .image-box-cover{
                position: absolute;
                background: linear-gradient(
                    0deg, 
                    rgba(135, 100, 100, 0.2),
                    #181818
                    );
                opacity: 0;
                transition: 0.5s;
                z-index: 3;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
              
            
    
                button{
                    background: #181818;
                    position: absolute;
                    height: 48px;
                    width: auto;
                    font-size: 1.8rem;
                    text-align: center;
                    cursor: pointer;
                    border: solid 2px #01aa6d;
                    color: #01aa6d;
                    border-radius: 20px;
                    font-family: 'Coolvetica';
                    left: 10px;
                    right: 10px;
                    
                    &:hover{
                        background: #01aa6d;
                        color: #181818;
                        transition: .5s;
    
                    }
                    
                }
                .button-github{
                    top: 20%;
                }
                .button-website{
                    bottom: 20%;
                }

                &:hover{
                    opacity: 1;
                }
            
        }
        
        

       

        .portfolio-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            // object-fit: cover;
        }
    }

    
 
}

   .projects-page::-webkit-scrollbar {
    width: 1em;
  }
   
  .projects-page::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  .projects-page::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }



.page{

    .top-tags{
        top: 4px;
     }
}
@media(max-width: 799px){
    .projects-page{
        margin-top: 60px;
    }
}
@media(max-width: 1000px) {
    .container{
        width: 100%;
        padding-bottom: 0;
        box-sizing: border-box;
    }
        .projects-page{
            padding: 30px;
            .images-container{
                margin-left: 5%;
                
            }
            .images-box {
                padding: 0;
                height: 400px;
                width: 260px;
                overflow: visible;
                margin-bottom: 20px;
                


            .portfolio-image{
                
                height: 400px;
                object-fit: fill;
            }
            }////position of the projects

        }
        h1.page-title{
            margin-left: 6%;
            margin-top: 30px;

            &::before{
                top: 4px;
                left: 1%;
            }

            &::after{
                bottom: 0;
                left: 40%;
            }
        }
        
        .images-box{
        
            width: 150px;
        }
    
    }
    
    @media(max-width: 600px) {
        h1.page-title{
         

            
            &::after{
                bottom: 0;
                left: 150px;
            }
        }
    }
