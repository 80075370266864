.home-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }
    h1 {
        color: whitesmoke;
        font-size: 56px;
        line-height: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        &::before {
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: #01aa6d;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: -15px;
            opacity: 0.6;
        }
        &::after{
            content: '</h1>';
            font-family: 'La Belle Aurore';
            color: #01aa6d;
            font-size: 18px;
            position: absolute;
            margin-top: 32px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }
        img {
            width :99px;
            margin-right: -33px;
            margin-bottom: -17px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
        .logo-p{
            margin-right: -30px;
            margin-bottom: -12px;
        }
    }
    .flat-button {
        color: #01aa6d;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid  #01aa6d;
        border-radius: 8px;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background: #01aa6d;
            color: #000000;
        }
    }
    
}

@media (max-width: 800px){
    .home-page{
        .text-zone{
            margin-top: 60px;
        }
        h1{
            margin-top: 40px;
            margin-left: -30px;
            font-size: 3.0rem;
            line-height: 46px;

            
            img{
                width: 85px;
                height: 80px;
                margin-left: -13px;
            }
            .logo-p{
                margin-right: -27px;
            }
            &::before {
                margin-top: -30px;
                left: 10px;
            }
            &::after {
                margin: 50px;
            }
        }
        
        .logo-container{
            top: 400px;
            right: 10px;
        }

    }
}



@media(max-width: 600px){
    .page{

        .container{
            .text-zone{
                position: initial;
                width: 90%;
                transform: none;
                // margin-top: 20px;
                box-sizing: border-box;
            h1{
                // margin-top: 36px;
                margin-left: 30px;
                
    
                &::before{
                    top: 56px;
                    left: 20px;
                }
                &::after{
                    // bottom: 0;
                    margin-bottom: 50px;
                                    
                }
               
            }
        }
    }
    }
    .home-page{
        // .text-zone{
        //     position: initial;
        //     width: 90%;
        //     transform: none;
        //     margin-top: 20px;
        //     box-sizing: border-box;

            // h1{
            //     margin-top: 36px;
            //     margin-left: 30px;
                
    
            //     &::before{
            //         top: 50px;
            //         margin-left: 20px;
            //     }
            //     &::after{
            //         top: 0;
            //         margin-left: 190px;                    
            //     }
               
            // }
        // }
        .flat-button{
            float: none;
            display: block;
            width: 125px;
            margin: 20px auto 0 auto;
        }.logo-container{
            display: none;
        }
        // .logo-container{
        //    position: relative;
        //    min-width: 150px;
        //    height: auto;
        //    top: 28px;
        //    left: 0;
        //    right: 0;
        //    margin: 0 auto;
        // }

        
    }
}